import React from "react"
import Player from "../../blocks/player"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  player: playersYaml(slug: { eq: "saveljevs" }) {
    ...PlayerInfo
  }
  stats: allStatsYaml {
    nodes {
      key
      value_ru
      average
    }
  }
}`

export default class Saveljevs extends Player {

  renderDescription() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>
          Игрок середины поля с отличным видением партнеров и чтением игры. Способен отдать передачу на любое расстояние, обыграть оппонента. Обладает навыками плеймейкера, кроме того, неплохо проявляет себя в отборе. 
          </p>
          <img src="/images/players/saveljevs/photo1.jpg" alt={ this.fullName() } />
          <p>
          Прикрывает мяч корпусом, нацелен на быстрый выход команды в контратаку, которую способен организовать первым действием. 
          </p>
          <img src="/images/players/saveljevs/photo2.jpg" alt={ this.fullName() } />
          <p>
          За счет чтения игры умеет вовремя страховать партнеров в середине поля, старается быстро разворачиваться лицом к чужим воротам. Всегда вступает в прессинг после потери или быстро восстанавливает позицию.
          </p>
          <img src="/images/players/saveljevs/photo3.jpg" alt={ this.fullName() } />
          <p>
          Один из лидеров молодёжной сборной Латвии, уже получил свой первый вызов в национальную сборную Латвии, основной игрок Примаверы «Вероны» в статусе вице-капитана, привлекается также к матчам за основной состав.
          </p>

          <div className="video">
            <iframe
              src="https://www.youtube.com/embed/_xNKkyttqrE"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>

        </div>
      </div>
    )
  }
}